import styled from "styled-components";

export const LargeTitle = styled.h1`
    font-size: 60px;
    line-height: 70px;
    font-weight: 900;
    text-transform: uppercase;
    color: #444;
    text-align: left;

  @media only screen and (max-width: 1080px) {
        font-size: 50px;
    line-height: 60px;

  }
  @media only screen and (max-width: 880px) {
      line-height: 45px;
      font-size: 45px;
    }
    @media only screen and (max-width: 680px) {
        line-height: 30px;
        font-size: 33px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 25px;
        font-size: 23px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 25px;
        font-size: 23px;
    }
    @media only screen and (max-width: 360px) {
        line-height: 20px;
        font-size: 20px;
    }
    `;

export const MediumTitle = styled.h2`
    font-size: 43px;
    color: #444;
    line-height: 60px;
    margin-top: 2rem;
    text-transform: uppercase;
    font-weight: 900;
    align-self: left;
    @media only screen and (max-width: 1080px) {
        font-size: 40px;
        margin-top: .7rem;
    }
      @media only screen and (max-width: 880px) {
      line-height: 45px;
      font-size: 31px;
      margin-top: 1.1rem;
    }
    @media only screen and (max-width: 680px) {
        line-height: 30px;
        font-size: 23px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 35px;
        font-size: 20px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 35px;
        font-size: 18px;
        margin-left: .2rem;
    }
    `;
export const MediumContactTitle = styled.h2`
    font-size: 39px;
    color: #444;
    line-height: 60px;
    margin-top: 2rem;
    text-transform: uppercase;
    font-weight: 900;
    align-self: left;
    @media only screen and (max-width: 1080px) {
        font-size: 32px;
        margin-top: .7rem;
    }
      @media only screen and (max-width: 880px) {
      line-height: 45px;
      font-size: 26px;
      margin-top: 1.1rem;
    }
    @media only screen and (max-width: 680px) {
        line-height: 30px;
        font-size: 22px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 35px;
        font-size: 19px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 35px;
        font-size: 17px;
        margin-left: .2rem;
    }
    `;

export const SmallTitle = styled.h3`
font-size: 38px;
color: #fff;
text-transform: uppercase;
font-weight: 800;
padding-top: 50%;
margin-top: -57px;
position: relative;
margin-bottom: auto;
@media only screen and (max-width: 1080px) {
    font-size: 32px;
    margin-top: -46px;
}
@media only screen and (max-width: 880px) {
    font-size: 23px;
    margin-top: -34px;
font-weight: 800;
}
@media only screen and (max-width: 680px) {
        font-size: 18px;
    margin-top: -26px;
    font-weight: 700;
}
@media only screen and (max-width: 480px) {
    font-size: 14px;
    margin-top: -21px;
       
    }
@media only screen and (max-width: 360px) {
    font-size: 13px;
    margin-top: -19px;
       
    }
`;

export const SmallText = styled.p`
    font-size: 20px;
    color: #333;
    font-weight: 400;
    text-align: left;
    align-self: left;
    @media only screen and (max-width: 1080px) {
          font-size: 18px;
    }
    @media only screen and (max-width: 880px) {
          font-size: 15px;
    }
    @media only screen and (max-width: 680px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 35px;
        font-size: 23px;
    }
    
    `;
export const BottomText = styled.p`
    font-size: 15px;
    color: #333;
    font-weight: 400;
    text-align: left;
    align-self: left;
    margin-top: 2rem;

    @media only screen and (max-width: 1080px) {
          font-size: 14px;
    }
    /* @media only screen and (max-width: 880px) {
          font-size: 15px;
    }
    @media only screen and (max-width: 680px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 35px;
        font-size: 23px;
    } */
    
    `;
    export const BottomSpanText = styled.span`
    font-size: 15px;
    color: #e26c1d;
    font-weight: 400;
    text-align: left;
    align-self: left;
    @media only screen and (max-width: 1080px) {
          font-size: 14px;
    }
    /* @media only screen and (max-width: 880px) {
          font-size: 15px;
    }
    @media only screen and (max-width: 680px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 35px;
        font-size: 23px;
    } */
    
    `;

export const LargeSpanTitle = styled.span`
    font-size: 55px;
    line-height: 60px;
    margin: 1rem 0;
    font-weight: 900;
    text-transform: uppercase;
    color: #e26c1d;
    text-align: left;
    align-self: left;
    @media only screen and (max-width: 1080px) {
          font-size: 50px;
          margin-top: .8rem;
        }
        @media only screen and (max-width: 680px) {
        font-size: 45px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 35px;
        font-size: 23px;
    }
        `;

export const MediumSpanTitle = styled.span`
    font-size: 53px;
    font-weight: 900;
    text-transform: uppercase;
    color: #e26c1d;
    text-align: left;
    align-self: left;
    @media only screen and (max-width: 1080px) {
        font-size: 43px;
        margin-top: -5rem;
    }
      @media only screen and (max-width: 880px) {
      line-height: 45px;
      font-size: 36px;
    }
    @media only screen and (max-width: 680px) {
        font-size: 26px;
    }
    @media only screen and (max-width: 480px) {
        line-height: 25px;
        font-size: 21px;
    }
    @media only screen and (max-width: 360px) {
        line-height: 25px;
        font-size: 19px;
    }
    `;

export const SmallSpanText = styled.span`
    font-size: 20px;
    margin-top: .2rem;
    margin-left: .2rem;
    color: #555;
    font-weight: 400;
    text-align: left;
    align-self: left;
    @media only screen and (max-width: 1080px) {
        font-size: 20px;
        margin-top: 0;
    }
    @media only screen and (max-width: 880px) {
          font-size: 16px;
    }
    @media only screen and (max-width: 680px) {
        font-size: 15px;
        
    }
    @media only screen and (max-width: 480px) {
        font-size: 13px;
        font-weight: 400;
    }
    @media only screen and (max-width: 360px) {
        font-size: 13px;
    }
    `;

export const SmallSpanOrangeText = styled.span`
    font-size: 20px;
    margin-top: .2rem;
    margin-left: .2rem;
    color: #e26c1d;
    font-weight: 400;
    text-align: left;
    align-self: left;
    @media only screen and (max-width: 1080px) {
        font-size: 20px;
        margin-top: .6rem;
    }
    @media only screen and (max-width: 880px) {
          font-size: 16px;
    }
    @media only screen and (max-width: 680px) {
        font-size: 15px;
    }
    @media only screen and (max-width: 480px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 480px) {
        font-size: 13px;
    margin-top: 0.2rem;
    }
    `;