// import styled from "styled-components"
import styled from "styled-components";
import Circle from "../assets/glow/banner-circle.webp";
// import styled, { css } from "styled-components";
import ServiciosGLOW from "../assets/pdf/ServiciosGLOW.pdf";


const Container = styled.div`
  /* height: calc(100vh - 10px); */
  display: flex;
  padding: 170px 20px;
  padding-top: 190px;
  @media only screen and (max-width: 480px) {
    padding: 90px 30px;
    padding-bottom: 60px;
  }
`;

const Left = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

const LeftTitles = styled.div`
  width: 80%;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  @media only screen and (max-width: 1080px) {
    width: 88%;
  }
  @media only screen and (max-width: 640px) {
    width: 95%;
  }
`;

const TitleH1 = styled.h1`
  width: 100%;
  font-size: 55px;
  text-transform: uppercase;
  font-weight: 900;
  /* margin-left: -130px; */
  @media only screen and (max-width: 950px) {
    width: 100%;
    font-size: 45px;
  }
  @media only screen and (max-width: 640px) {
    font-size: 35px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const TitleH2 = styled.h2`
  width: 50%;
  font-size: 44px;
  margin-top: 0px;
  text-transform: uppercase;
  font-weight: 900;
  /* margin-left: -175px; */
  @media only screen and (max-width: 880px) {
    width: 100%;
    font-size: 38px;
  }
  @media only screen and (max-width: 640px) {
    font-size: 31px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 28px;
  }
`;

const TitleSpan = styled.span`
  width: 60%;
  font-size: 55px;
  color: #e26c1d;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 60px;
  /* margin-left: -180px; */
  @media only screen and (max-width: 880px) {
    width: 100%;
    font-size: 45px;
  }
  @media only screen and (max-width: 640px) {
  line-height: 40px;
    font-size: 35px;
  }
  @media only screen and (max-width: 480px) {
  line-height: 35px;
    font-size: 30px;
  }
`;

const Desc = styled.p`
  width: 60%;
  font-size: 20px;
  margin-top: 20px;
  margin-left: -100px;
  @media only screen and (max-width: 880px) {
    width: 77%;
  margin-left: -80px;
  font-size: 18px;

  }
  @media only screen and (max-width: 640px) {
    font-size: 14px;
    margin-left: -65px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 12px;
    width: 84%;

  }
`;

const Info = styled.div`
  width: 70%;
  display: flex;
  justify-content: left;
  gap: 30px;
  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
  @media only screen and (max-width: 640px) {
    gap: 15px;
  }
  @media only screen and (max-width: 385px) {
    flex-direction: column;
  }
  `;

const GlowPdf = styled.a`
  font-size: 17px;
  text-decoration: none;
  width: fit-content;
  border-bottom: darkorange solid 2px;
  color:#333;
  padding-inline:2px;
  padding-bottom: 3px;
  cursor: pointer;
  &:hover, &:focus {
    opacity: .8;
  }
  @media only screen and (max-width: 640px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;


const Right = styled.div`
  width: 30%;
  transform: translateY(50%);
  /* @media only screen and (max-width: 1080px) {
    display: none;
  } */
`;

const Image = styled.img`
  background: white;
  padding: 10px;
  margin-left: -70px;
  border-radius: 50%;
  width: 450px;
  transform: translateY(-50%);
  z-index: 104;
  @media only screen and (max-width: 1080px) {
    width: 300px;
    margin-top: 20px;
  }
  @media only screen and (max-width: 640px) {
    width: 230px;
  transform: translateY(-60%);
  margin-top: 60px;
  margin-left: -30px;}
  @media only screen and (max-width: 480px) {
    width: 165px;
  padding: 5px;
  transform: translateY(-60%);
  margin-left: -50px;
  }
`;

const Intro = () => {
  return (
    <Container>
      <Left>
        <Desc>
        <LeftTitles>
        <TitleH2>La</TitleH2>
        <TitleSpan>experiencia</TitleSpan>
        <TitleH1>en elegir</TitleH1>
        </LeftTitles>
          Las propuestas que brindamos se adaptan a cada realidad, no trabajamos
          con capacitaciones preestablecidas. Cada uno de nuestros servicios se
          realizan después de efectuar un diagnóstico a medida.
          <br /><br />
          <Info>
        <GlowPdf href={ServiciosGLOW}
        target="_blank"
        rel="noreferrer">Ver en PDF</GlowPdf>
        <GlowPdf href={ServiciosGLOW}  download="ServiciosGlow"
        target="_blank"
        rel="noreferrer">Descargar PDF</GlowPdf>
        </Info>
        </Desc>
        <br />
      </Left>
      <Right>
        <Image src={Circle} />
      </Right>
    </Container>
  );
};

export default Intro;
