import styled from "styled-components";
import {useState} from 'react';
import { FaChevronDown } from "react-icons/fa"


const Item = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 890px) {
      width: 100%;
    }
    `;

const ServiceItemG = styled.button`
  width: 80%;
  font-size: 22px;
  text-align: left;
  color: #fff;
  background: linear-gradient(to right , #595858, #444444);
  margin: 10px 0;
  margin-inline: auto;
  border: none;
  padding: 12px 20px;
  border-radius: 12px;
  @media only screen and (max-width: 940px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const Arrow = styled.span`
  font-size: 14px;
  text-align: left;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const ServiceItemInfo = styled.p`
  width: 85%;
  font-size: 14px;
  text-align: left;
  color: #333;
  margin: 10px 0;
  padding: 0 40px;
  margin-inline: auto;
  @media only screen and (max-width: 890px) {
    width: 100%;
    font-size: 15px;
    padding: 0 15px;
  }
`;


const ColorText = styled.p`
  width: 85%;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  color: #ee5a27;
  margin: 10px 0;
  padding: 0 40px;
  margin-inline: auto;

  @media only screen and (max-width: 890px) {
    width: 100%;
    font-size: 15px;
    padding: 0 15px;
  }
`;

export default function Item5() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {

    setIsShown(current => !current);

  };

  return (
    <Item>
      <ServiceItemG onClick={handleClick}>Mentorías y Coaching Laboral<Arrow><FaChevronDown /></Arrow></ServiceItemG>

      {isShown ? (
        <div>
          <ColorText>
          Espacio uno a uno para poder trabajar las debilidades laborales y potencializar aquellos aspectos a resaltar en el día a día.
          </ColorText>
          <ServiceItemInfo>
          Mentorías presenciales o virtuales que te ayudarán a vos o a tus colaboradores a crecer y a lograr tener un potencial crecimiento profesional.<br />
          La adaptación a los cambios continuos que hoy en día se instalanen este mundo requieren que aquellos que desarrollan 
          funciones de liderazgo adquieran habilidades esenciales.<br /><br />
          Algunas de ellas son:
          </ServiceItemInfo>
          <ColorText>
            Adaptabilidada diferentesescenarios
          </ColorText>
          <ColorText>
            Gestión de emocionalidad
          </ColorText>
          <ColorText>
            Lograr con formar equipos de alto desempeño, productivos y comprometidos
          </ColorText>
          <ServiceItemInfo>
          Por todo esto nuestro COACHING va a funcionar como facilitador en la gestión de cambios permitiendo una reestructuración en la 
          organización y un enfoque integral del proceso en forma individual, de equipos y organizacional.
          </ServiceItemInfo>
        </div>
      ) : null}

    </Item>
  );
}
