// import styled from "styled-components"
import styled from "styled-components";
import Icon1 from "../assets/glow/icon-1.png"
import Icon2 from "../assets/glow/icon-2.png"
import Icon3 from "../assets/glow/icon-3.png"

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  @media only screen and (max-width: 1080px) {
      flex-direction: column;
    }
    `;

const Left = styled.div`
  width: 100%;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #8b8a8a;
  background-color: #444;
    color: #fff;
  @media only screen and (max-width: 480px) {
    padding: 80px 0;
    }
    `;

const LeftH2 = styled.h4`
    width: 90%;
    font-size: 45px;
    text-transform: uppercase;
    margin-bottom: 40px;
    text-align: left;
    color: #f57926;
    font-weight: 800;
    /* margin-left: -130px; */
    @media only screen and (max-width: 480px) {
    width: 100%;
    font-size: 36px;
    margin-bottom: 22px;

    }
`;

const LeftH4 = styled.h4`
    width: 90%;
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 30px;
    text-align: left;
    color: #fff;
    font-weight: 800;
    /* margin-left: -130px; */
    @media only screen and (max-width: 480px) {
    width: 100%;
    font-size: 22px;
    margin-bottom: 25px;
    }
`;

const SpanH4 = styled.span`
    width: 90%;
    font-size: 25px;
    text-transform: uppercase;
    text-align: left;
    color: #f57926;
    /* margin-left: -130px; */
    @media only screen and (max-width: 480px) {
    font-size: 22px;
    }
`;

const LeftDesc = styled.p`
  width: 80%;
  font-size: 22px;
  text-align: left;
  color: #fff;
  @media only screen and (max-width: 680px) {
    font-size: 18px;
  }
`;


const Right = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #d8d5d7;
  @media only screen and (max-width: 1080px) {
    padding: 80px 0;
    gap: 40px;
  };
`;


const Item = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    gap: 10px;
    @media only screen and (max-width: 1080px) {
    flex-direction: column;
    text-align: center;
    gap: 0px;
    &:nth-last-of-type() {
      margin-top: 35px;
    };
    }
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;
  margin-top: auto;
  margin-bottom: auto;
  @media only screen and (max-width: 1080px) {
    &:nth-of-type(3) {
      margin-top: 35px;
    };
    }
`;

const RightDesc = styled.p`
  width: 90%;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
  @media only screen and (max-width: 780px) {
    font-size: 14px;
  }
`;



const Recruiting = () => {
  return (
    <Container>
      <Left>
        <LeftDesc>
        <LeftH2>RECRUITING</LeftH2>
        <LeftH4>Búsqueda y selección<SpanH4>de talentos</SpanH4> </LeftH4>
        Identificamos a los mejores perfiles que cumplan con las aptitudes, competencias y habilidades técnicas necesarias, para un buen desempeño.
        </LeftDesc>
      </Left>
      <Right> 
        <Item>
          <Icon src={Icon1} />
        <RightDesc>
          Contamos con un equipo interdisciplinario que llevará a cabo un trabajo estratégico realizando búsquedas de diversos perfiles para puestos solicitados porpymesyempresas.
        </RightDesc>
        </Item>
        <Item>
          <Icon src={Icon2} />
        <RightDesc>
        Nos orientamos e n perfiles: gerenciales, mandos medios, profesionales y analistas, administrativos y comerciales estratégicos.</RightDesc>
        </Item>
        <Item>
          <Icon src={Icon3} />
        <RightDesc>
          Contamos con un equipo interdisciplinario que llevará a cabo un trabajo estratégico realizando búsquedas de diversos perfiles para puestos solicitados porpymesyempresas. 
        </RightDesc>
        </Item>
      </Right>
    </Container>
  );
};

export default Recruiting;
