import styled from "styled-components";
import {useState} from 'react';
import { FaChevronDown } from "react-icons/fa"


const Item = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 890px) {
      width: 100%;
    }
    `;

const ServiceItemO = styled.button`
  width: 80%;
  font-size: 22px;
  text-align: left;
  color: #fff;
  background: linear-gradient(to right , #f59121, #f16b3e, #ee5a27);
  margin: 10px 0;
  margin-inline: auto;
  border: none;
  padding: 12px 20px;
  border-radius: 12px;
  @media only screen and (max-width: 940px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const Arrow = styled.span`
  font-size: 14px;
  text-align: left;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const ServiceItemInfo = styled.p`
  width: 85%;
  font-size: 14px;
  text-align: left;
  color: #333;
  margin: 10px 0;
  padding: 0 40px;
  margin-inline: auto;
  @media only screen and (max-width: 890px) {
    width: 100%;
    font-size: 15px;
    padding: 0 15px;
  }
`;


export default function Item6() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {

    setIsShown(current => !current);

  };

  return (
    <Item>
      <ServiceItemO onClick={handleClick}>Coaching Ejecutivo<Arrow><FaChevronDown /></Arrow></ServiceItemO>

      {isShown ? (
        <div>
          <ServiceItemInfo>
            Es un proceso de acompañamiento a un equipo de trabajo para lograr que sus objetivos se alineen con sus acciones. 
            Generar y fomentar la cooperación entre sus miembros, ayudándolos a revisar y mejorar sus relaciones, procesos de trabajo y valores. <br />
            Se orienta al desarrollo de nuevos aprendizajes conductuales y de autoconocimiento potenciando el crecimiento profesional y el 
            ejercicio del liderazgo en sí mismo así como en los equipos de trabajo a su cargo.
          </ServiceItemInfo>        
        </div>
      ) : null}

    </Item>
  );
}
