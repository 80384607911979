import styled from "styled-components";
import {useState} from 'react';
import { FaChevronDown } from "react-icons/fa"


const Item = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 890px) {
      width: 100%;
    }
    `;

const ServiceItemG = styled.button`
  width: 80%;
  font-size: 22px;
  text-align: left;
  color: #fff;
  background: linear-gradient(to right , #595858, #444444);
  margin: 10px 0;
  margin-inline: auto;
  border: none;
  padding: 12px 20px;
  border-radius: 12px;
  @media only screen and (max-width: 940px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const Arrow = styled.span`
  font-size: 14px;
  text-align: left;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const ServiceItemInfo = styled.p`
  width: 85%;
  font-size: 14px;
  text-align: left;
  color: #333;
  margin: 10px 0;
  padding: 0 40px;
  margin-inline: auto;
  @media only screen and (max-width: 890px) {
    width: 100%;
    font-size: 15px;
    padding: 0 15px;
  }
`;

export default function Item7() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {

    setIsShown(current => !current);

  };

  return (
    <Item>
      <ServiceItemG onClick={handleClick}>Consultoría Empresarial<Arrow><FaChevronDown /></Arrow></ServiceItemG>

      {isShown ? (
        <div>
          <ServiceItemInfo>
            En Glow nos enfocamos en las personas como agentes de cambio, sin perder de vista la cultura organizacional, por ello; 
            desarrollamos proyectos como procesos tangibles y sustentables, mediante acciones ágiles y eficientes, generando oportunidades.<br />
            En los espacios de asesorías podemos acompañarte en la descripción y delimitación de puestos laborales, evaluación de desempeño, 
            evaluación de potencial de equipos, encuestas de clima laboral y encuentros para acompañar a los CEOs de las pymesempresas y 
            lograr aumentar el rendimiento de sus equipos de trabajo.
          </ServiceItemInfo>
        </div>
      ) : null}

    </Item>
  );
}
