import { SecondaryPageContainer } from "../components/Containers/styles";
import ContactBanner from "../components/soyGlow/ContactBanner";
import styled, { css } from "styled-components";
import AddContact from "../components/soyGlow/AddContact";
import Footer from "../components/Footer";


const Shape = css`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const IntoShape = styled.div`
  ${Shape}
  clip-path: polygon(85% 0%, 100% 0%, 100% 100%, 75% 100%);
  background-color: #333333;
  @media only screen and (max-width: 880px) {
    clip-path: polygon(85% 0%, 100% 0%, 100% 100%, 75% 100%);
  }
  @media only screen and (max-width: 680px) {
    clip-path: polygon(85% 0%, 100% 0%, 100% 100%, 75% 100%);
  }
  @media only screen and (max-width: 480px) {
    clip-path: polygon(85% 0%, 100% 0%, 100% 100%, 70% 100%);
  }
`;


const ContactUs = () => {

    return (
        <>
        <IntoShape />
        <SecondaryPageContainer>
            <ContactBanner />
            <AddContact />
            <Footer />
        </SecondaryPageContainer>
        </>
    );
};

export default ContactUs;