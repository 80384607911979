import styled from "styled-components";
import GlowIcon from "../assets/glow/icon-w.webp"


const Container = styled.div`
  /* height: calc(55vh - 10px); */
  display: flex;
  flex-direction: column;
  padding: 120px 0;
  position: relative;
  background: linear-gradient(to right , #f59121, #ee5a27);
  font-weight: lighter;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @media only screen and (max-width: 980px) {
    padding: 100px 0;
    }
  @media only screen and (max-width: 680px) {
    padding: 80px 0;
    }
    `;

const Desc = styled.p`
  width: 90%;
  font-size: 26px;
  text-align: center;
  color: #fff;
  @media only screen and (max-width: 980px) {
    font-size: 22px;
    margin: 8px;
    margin-top: -10px;
  }
  @media only screen and (max-width: 680px) {
    font-size: 19px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const GIcon = styled.img`
position: absolute;
right: 0;
bottom: 0;
  margin-left: auto;
  margin-right: 40px;
  margin-top: auto;
  margin-bottom: 20px;
  width: 90px;
  z-index: 104;
  @media only screen and (max-width: 980px) {
    width:70px;
  margin-bottom: 15px;
  margin-right: 20px;
  }
  @media only screen and (max-width: 680px) {
    width:60px;
  margin-bottom: 10px;
  }
`;



const Description = () => {
    return (
      <Container>
        <Desc>
          Se establecen nuevas metas en gestión personal a través de un programa diseñado a medida.<br />
        </Desc>
        <Desc>
          Acompañamos durante el proceso de desarrollo para eficientizar la reinserción del profesional.          
        </Desc>
        <GIcon src={GlowIcon} />
      </Container>
    );
  };
  
  export default Description;