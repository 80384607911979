import "../components/settings/style.css";
import AboutGlow from "../components/AboutUsGlow"
import styled from "styled-components";
import Footer from "../components/Footer";


const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
  width: 100%;
  top: 0;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-family: 'Open Sans', sans-serif;
  background: transparent;

& h1 {
  font-family: "Open Sans", sans-serif;
}

& h2 {
  font-family: "Open Sans", sans-serif;
}

& h3 {
  font-family: "Open Sans", sans-serif;
}

& h4 {
  font-family: "Open Sans", sans-serif;
}

& h5 {
  font-family: "Open Sans", sans-serif;
}

& h6 {
  font-family: "Open Sans", sans-serif;
}

& p {
  font-family: "Open Sans", sans-serif;
}

& span {
  font-family: "Open Sans", sans-serif;
}

& a {
  font-family: "Open Sans", sans-serif;
}
`;

function About() {
  return (
    <>
      <Container>
        <AboutGlow />
        <Footer />
      </Container>
    </>
  );
}

export default About;