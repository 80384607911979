import styled from "styled-components";
import { LargeTitle, SmallSpanOrangeText, SmallSpanText, MediumSpanTitle, MediumContactTitle, SmallTitle } from "../utilities/styles";





const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
  display: flex;
  padding: 2rem;
  gap: 2rem;
  height: 500px;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  @media only screen and (max-width: 880px) {
      margin-top: -3rem;
  height: 350px;

    }
  @media only screen and (max-width: 480px) {
    gap: .2rem;
    padding:1rem;
    margin-bottom: -rem;
    }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2.2;
  font-family: "Open Sans", sans-serif;
  /* padding-left: 3rem; */
  padding-top: 2rem;
  padding-left: 5rem;
  @media only screen and (max-width: 1300px) {
    padding-left: 3rem;
  }
  @media only screen and (max-width: 1080px) {
    padding-top: 0;
    padding-left: 2rem;
  }
  @media only screen and (max-width: 880px) {
    margin-top: .5rem;
    padding-left: 1rem;
  }
  @media only screen and (max-width: 480px) {
    flex: 2.4;
    margin-top: -.8rem;
    padding-left: .3rem;
  }
  `;


const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: "Open Sans", sans-serif;
  margin-top: 3rem;
  @media only screen and (max-width: 1080px) {
    margin-top: 2rem;
  }
  @media only screen and (max-width: 680px) {
    margin-top: 2.3rem;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 1rem;
    flex:1;
  }
  @media only screen and (max-width: 360px) {
    flex:1;
  }

`;
const RightCircle = styled.div`
  background: linear-gradient(to right , #f59121 30%, #ee5527 80%);
  width: 270px;
  height: 270px;
  border-radius: 50%;
  border: solid 13px white;
  text-align: center;
  @media only screen and (max-width: 1080px) {
    width: 220px;
    height: 220px;
  border: solid 11px white;
  }
  @media only screen and (max-width: 880px) {
    width: 170px;
    height: 170px;
  border: solid 10px white;
  }
  @media only screen and (max-width: 680px) {
    width: 130px;
    height: 130px;
  border: solid 8px white;
  }
  @media only screen and (max-width: 480px) {
    width: 90px;
    height: 90px;
  border: solid 6px white;
  }
  @media only screen and (max-width: 360px) {
    width: 90px;
    height: 90px;
  border: solid 6px white;
  }
`;




const ContactBanner = () => {

  return (
    <Container>
      <Left>
        <LargeTitle>Nuestros&nbsp;servicios<br />
          <MediumSpanTitle>
            son&nbsp;a&nbsp;medida
          </MediumSpanTitle>
        </LargeTitle>
        <MediumContactTitle>Nuestras propuestas</MediumContactTitle>
        <SmallSpanText>Se adaptan a cada realidad, no trabajamos con capacitaciones preestablecidas.
        &nbsp;Cada uno de nuestros servicios se realizan <SmallSpanOrangeText>después de efectuar un diagnóstico a medida.</SmallSpanOrangeText></SmallSpanText>
      </Left>
      <Right>
        <RightCircle>
          <SmallTitle>Soy<br />Glow</SmallTitle>
        </RightCircle>
      </Right>
    </Container>
  );
};



export default ContactBanner;
