import React from "react";
import styled from "styled-components";
import "./personForm.css";
import LogoTuEmpleo from "../../assets/glow/logo-tuempleo.webp";
const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
  display: flex;
  flex-direction: column;
  background: rgb(240, 240, 240);
  margin-top: 0;
  padding-bottom: 3rem;
  padding-top: 0;
  gap: 2rem;
  text-align: left;
  overflow: hidden; /* Hide scrollbars */

  font-family: "Open Sans", sans-serif;
  @media only screen and (max-width: 880px) {
    padding-bottom: 4rem;
  }
`;

const TuEmpleo = styled.div`
  display: flex;
  background-color: rgba(64, 97, 238, 1);
  margin: 0;
  margin-bottom: 30px;
  padding-inline: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  width: 100%;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
    padding: 10px;
    gap: 0;
    padding-bottom: 20px;
  }
`;

const PersonForm = () => {
  return (
    <Container>
      <a className="tu-empleo" href="https://tuempleo.ar" target="blank">
        <TuEmpleo>
          <img
            className="tuempleo-logo"
            src={LogoTuEmpleo}
            alt="Logo de tuempleo.ar"
          />
          <h2 className="tuempleo-text">
            Gestionamos tu proximo empleo con los trabajos listados en
            <span> TuEmpleo.ar</span>
          </h2>
        </TuEmpleo>
      </a>
      <iframe
        className="iframe-form"
        src="https://forms.glowconsultora.com.ar/persona.html"
      ></iframe>
    </Container>
  );
};

export default PersonForm;
