import styled from "styled-components";
import Logo from "../assets/glow/glow-logo.webp";
import DigitalBricks from "./DigitalBricks";
import MarcaGlow from "../assets/zip/GlowMarca.zip";
import { SiLinktree } from "react-icons/si";
import {
  AiOutlineMail,
  AiOutlineEnvironment,
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineLinkedin,
} from "react-icons/ai";

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  background-color: #444;
  padding: 40px 30px;
  justify-content: space-between;
  @media only screen and (max-width: 1080px) {
    gap: 4rem;
  }
  @media only screen and (max-width: 860px) {
    height: 375px;
  }
  @media only screen and (max-width: 760px) {
    flex-direction: column;
    height: auto;
  }
`;

const First = styled.div`
  width: 35%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  @media only screen and (max-width: 1080px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media only screen and (max-width: 860px) {
    width: 30%;
  }
  @media only screen and (max-width: 760px) {
    width: fit-content;
    align-items: left;
    justify-content: left;
    text-align: left;
    margin-inline: auto;
  }
`;

const FooFirstTittle = styled.h2`
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 1px;
  color: white;
  @media only screen and (max-width: 1210px) {
    font-size: 23px;
  }
  /* @media only screen and (max-width: 760px) {
    align-self: ;
  }; */
`;

const FirstSpan = styled.span`
  color: #f5a021;
  margin-right: 8px;
`;

const ContactInfo = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
  @media only screen and (max-width: 1080px) {
    align-items: center;
    justify-content: center;
    text-align: left;
  }
`;

const Link = styled.a`
  display: flex;
  text-decoration: none;
  color: white;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  &:hover,
  &:focus {
    color: #f5a021;
  }
  /* @media only screen and (max-width: 1080) {
    justify-content: center;  
  }; */
`;

const Email = styled(AiOutlineMail)`
  color: #f5b221;
  font-size: 25px;
  @media only screen and (max-width: 1080px) {
    text-align: left;
  }
`;
const Location = styled(AiOutlineEnvironment)`
  color: #f5b221;
  font-size: 26px;
  @media only screen and (max-width: 1080px) {
    margin-left: -40px;
  }
`;
const WhatsApp = styled(AiOutlineWhatsApp)`
  color: #f5b221;
  font-size: 26px;
  @media only screen and (max-width: 1080px) {
    margin-left: -110px;
  }
`;

const Second = styled.div`
  display: flex;
  width: 300px;
  margin: auto;
  @media only screen and (max-width: 1080px) {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    transform: translateY(50%);
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 7px;
  font-size: 14px;
  letter-spacing: 2px;
  color: white;
  text-align: center;
  font-weight: 600;
  align-items: center;
  @media only screen and (max-width: 1080px) {
    position: absolute;
    transform: translateY(-50%);
    margin-top: 30px;
  }
  @media only screen and (max-width: 860px) {
    width: 100%;
    margin-top: 110px;
  }
  @media only screen and (max-width: 760px) {
    position: relative;
    margin-top: 30px;
  }
`;

const FooterLogo = styled.img`
  width: 240px;
  @media only screen and (max-width: 1080px) {
    width: 170px;
  }
  @media only screen and (max-width: 860px) {
    width: 220px;
  }
`;

const Third = styled.div`
  width: 35%;
  margin-top: auto;
  margin-bottom: auto;
  @media only screen and (max-width: 860px) {
    margin-top: 8px;
  }
  @media only screen and (max-width: 760px) {
    width: 100%;
    margin-top: 30px;
  }
`;

const LastSection = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: -10px;
  margin-right: 0;
  margin-left: auto;
  @media only screen and (max-width: 760px) {
    margin-right: auto;
    align-items: center;
    text-align: center;
  }
`;

const FooLastTittle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 1px;
  color: white;
  padding-bottom: 20px;
`;

const FooSpan = styled.span`
  color: #f5a021;
`;

const FooterIcons = styled.div`
  display: flex;
  gap: 25px;
  @media only screen and (max-width: 760px) {
    padding-bottom: 20px;
  }
`;

const Instagram = styled(AiOutlineInstagram)`
  color: #fff;
  background-color: #e1306c;
  width: 3rem;
  height: 3rem;
  padding: 0.4rem;
  border-radius: 15px;
  cursor: pointer;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`;

const Facebook = styled(AiOutlineFacebook)`
  color: #fff;
  background-color: #3b5998;
  width: 3rem;
  height: 3rem;
  padding: 0.4rem;
  border-radius: 15px;
  cursor: pointer;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`;

const Linkedin = styled(AiOutlineLinkedin)`
  color: #fff;
  background-color: #0e76a8;
  width: 3rem;
  height: 3rem;
  padding: 0.6rem;
  border-radius: 15px;
  cursor: pointer;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`;

const Linktree = styled(SiLinktree)`
  color: #fff;
  background-color: #37c952;
  width: 3rem;
  height: 3rem;
  padding: 0.6rem;
  border-radius: 15px;
  cursor: pointer;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`;

const MarcaZip = styled.a`
  font-size: 18px;
  text-decoration: none;
  width: fit-content;
  margin-top: 1.5rem;
  padding-inline: 7px;
  padding-bottom: 6px;
  border-bottom: darkorange solid 2px;
  color: #fff;
  cursor: pointer;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  @media only screen and (max-width: 640px) {
    margin: 20px;
  }
`;

const Footer = () => {
  return (
    <>
      <Container>
        <First>
          <FooFirstTittle>
            <FirstSpan>Glow</FirstSpan>consultora
          </FooFirstTittle>
          <ContactInfo>
            <Link href="https://wa.me/5491178662024">
              <WhatsApp />
              +54 9 11 7866-2024
            </Link>
            <Link href="https://goo.gl/maps/RGBUL9zzFUyFjkyG9" target="_blank">
              <Location />
              Mitre 799, Berazategui Centro
            </Link>
            <Link href="emailto:conexion@glowconsultora.com.ar">
              <Email />
              conexion@glowconsultora.com.ar
            </Link>
          </ContactInfo>
        </First>
        <Second>
          <LogoWrapper>
            <FooterLogo src={Logo} />
            CONSULTORA
          </LogoWrapper>
        </Second>
        <Third>
          <LastSection>
            <FooLastTittle>
              Social <FooSpan>Glow</FooSpan>
            </FooLastTittle>
            <FooterIcons>
              <Link
                href="https://www.instagram.com/glowconsultora/"
                target="_blank"
              >
                <Instagram />
              </Link>
              <Link
                href="https://www.facebook.com/glowconsultora/"
                target="_blank"
              >
                <Facebook />
              </Link>
              <Link
                href="https://www.linkedin.com/in/georgina-ricci-387286234/?originalSubdomain=ar"
                target="_blank"
              >
                <Linkedin />
              </Link>
              <Link href="https://linktr.ee/glowconsultora" target="_blank">
                <Linktree />
              </Link>
            </FooterIcons>
            <MarcaZip
              href={MarcaGlow}
              download="Descargaste-los-logos-de-Glow-Consultora"
              target="_blank"
              rel="noreferrer"
            >
              Descargar Marca Glow.zip
            </MarcaZip>
          </LastSection>
        </Third>
      </Container>
      <DigitalBricks />
    </>
  );
};

export default Footer;
