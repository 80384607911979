import styled from "styled-components";
import {useState} from 'react';
import { FaChevronDown } from "react-icons/fa"


const Item = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 890px) {
      width: 100%;
    }
    `;

const ServiceItemO = styled.button`
  width: 80%;
  font-size: 22px;
  text-align: left;
  color: #fff;
  background: linear-gradient(to right , #f59121, #f16b3e, #ee5a27);
  margin: 10px 0;
  margin-inline: auto;
  border: none;
  padding: 12px 20px;
  border-radius: 12px;
  @media only screen and (max-width: 940px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const Arrow = styled.span`
  font-size: 14px;
  text-align: left;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const ServiceItemInfo = styled.p`
  width: 85%;
  font-size: 14px;
  text-align: left;
  color: #333;
  margin: 10px 0;
  padding: 0 40px;
  margin-inline: auto;
  @media only screen and (max-width: 890px) {
    width: 100%;
    font-size: 15px;
    padding: 0 15px;
  }
`;


export default function Item4() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {

    setIsShown(current => !current);

  };

  return (
    <Item>
      <ServiceItemO onClick={handleClick}>Capacitaciones<Arrow><FaChevronDown /></Arrow></ServiceItemO>

      {isShown ? (
        <div>
          <ServiceItemInfo>
            Los aprendizajes continuos te acercan al éxito seguro. 
            Ofrecemos capacitaciones para los diversos equipos, acorde a las necesidades de cada empresa. 
            Afrontando las problemáticas diarias y orientando en las habilidades para un crecimiento sustentable. 
            Capacitaciones presenciales y on-line diseñadas estratégicamente para cada demanda. Un amplio equipo de 
            profesionales diseña y facilita cada espacio de aprendizaje.
          </ServiceItemInfo>
        </div>
      ) : null}

    </Item>
  );
}
