// import "../components/settings/style.css";
// import { AiOutlineHome } from "react-icons/ai";
// import Banner from "../components/home/Banner";
import styled, { css } from "styled-components";
import Intro from "../components/Intro";
import Register from '../components/Register';
import Description from '../components/Description';
import Recruiting from "../components/Recruiting";
import ServiceList from "../components/ServiceList";
import AboutGlow from "../components/AboutGlow"
import Footer from "../components/Footer";

const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
  width: 100%;
  top: 0;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-family: 'Open Sans', sans-serif;
  background: transparent;

& h1 {
  font-family: "Open Sans", sans-serif;
}

& h2 {
  font-family: "Open Sans", sans-serif;
}

& h3 {
  font-family: "Open Sans", sans-serif;
}

& h4 {
  font-family: "Open Sans", sans-serif;
}

& h5 {
  font-family: "Open Sans", sans-serif;
}

& h6 {
  font-family: "Open Sans", sans-serif;
}

& p {
  font-family: "Open Sans", sans-serif;
}

& span {
  font-family: "Open Sans", sans-serif;
}

& a {
  font-family: "Open Sans", sans-serif;
}
`;

const Shape = css`
  width: 100%;
  height: 30%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const IntoShape = styled.div`
  ${Shape}
  margin-left:40px;
  clip-path: polygon(75% 0%, 100% 0%, 100% 100%, 55% 100%);
  background-color: #333333;
  @media only screen and (max-width: 880px) {
    clip-path: polygon(72% 0%, 100% 0%, 100% 100%, 35% 80%);
  }
  @media only screen and (max-width: 680px) {
    clip-path: polygon(72% 0%, 100% 0%, 100% 100%, 30% 75%);
  }
  @media only screen and (max-width: 480px) {
    clip-path: polygon(72% 0%, 100% 0%, 100% 100%, 25% 60%);
  }
`;


function Home() {
  return (
    // <div className="home">
    //   <div className="main-banner">
    //     <Intro />
    //     {/* <Banner /> */}
    //   </div>
      
    // </div>
    <>
          <Container>
          <Intro />
          <IntoShape />
          <Description />
          <Register />
          <Recruiting />
          <ServiceList />
          <AboutGlow />
          <Footer />
        </Container>
        </>
  );
}

export default Home;