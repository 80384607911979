// import styled from "styled-components"
import styled from "styled-components";
import Item1 from "./serviceItems/ServiceItem1"
import Item2 from "./serviceItems/ServiceItem2";
import Item3 from "./serviceItems/ServiceItem3";
import Item4 from "./serviceItems/ServiceItem4";
import Item5 from "./serviceItems/ServiceItem5";
import Item6 from "./serviceItems/ServiceItem6";
import Item7 from "./serviceItems/ServiceItem7";
import Item8 from "./serviceItems/ServiceItem8";
import Item9 from "./serviceItems/ServiceItem9";
import Item10 from "./serviceItems/ServiceItem10";
import Item11 from "./serviceItems/ServiceItem11";
import Item12 from "./serviceItems/ServiceItem12";
// import MainContainer from "./settings/style.js";

const Container = styled.div`
  display: flex;
  position: relative;
  height: fit-content;
  padding-bottom:130px;
  background-color: #f2f2f2;
  @media only screen and (max-width: 890px) {
      flex-direction: column;
    }
    `;

const Left = styled.div`
  width: 90%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: top;
  background-color: #8b8a8a;
  background-color: #f2f2f2;
    color: #fff;

  @media only screen and (max-width: 990px) {
      margin-inline: auto;
    }
    `;

const ServiceTitle = styled.div`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #8b8a8a;
  background-color: #f2f2f2;

  @media only screen and (max-width: 580px) {
      padding-top: 80px;
      padding-bottom: 50px;
    }
    `;


const LeftH3 = styled.h3`
    width: 80%;
    font-size: 50px;
    text-align: center;
    color: #333;
    font-weight: 700;
    text-transform: uppercase;
    /* font-family: "Open Sans", sans-serif; */
    @media only screen and (max-width: 580px) {
    width: 100%;
    font-size: 32px;
    }
`;

const SpanH3 = styled.span`
    width: 100%;
    font-size: 48px;
    text-align: left;
    color: #f57926;
    /* font-family: "Open Sans", sans-serif; */
    @media only screen and (max-width: 580px) {
    width: 100%;
    font-size: 32px;
    }
`;

const ServiceItems = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  `;

const Right = styled.div`
  width: 90%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -5%;
  justify-content: top;
  background-color: #f2f2f2;
  @media only screen and (max-width: 990px) {
    margin-inline: auto;

  }
`;



const ServiceList = () => {
  return (
    <>
    <ServiceTitle>
      <LeftH3>Nuestros <SpanH3>servicios</SpanH3> </LeftH3>
    </ServiceTitle>
    <Container>        
      <Left>
        <ServiceItems>
        <Item1 />
        <Item2 />
        <Item3 />
        <Item4 />
        <Item5 />
        <Item6 />
        </ServiceItems>
      </Left>
      <Right> 
        <ServiceItems>
        <Item7 />
        <Item8 />
        <Item9 />
        <Item10 />
        <Item11 />
        <Item12 />
        </ServiceItems>
      </Right>
    </Container>
    </>
  );
};

export default ServiceList;
