import styled from "styled-components";

export const SFlexContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
`;

export const SFixedContainer = styled.div`
    max-width: ${({ size }) => (!size ? "initial" : `${size}px`)};
    width: 100%;
    margin: 0px auto;
    padding: 6rem;
    border-radius: 15px;
    background: rgb(220, 220, 220, .4);
  /* border:  solid 1px #e26c1d; */
  /* background: rgb(255, 255, 255); */
  @media only screen and (max-width: 880px) {
    padding: 4rem 3rem;
    }
  @media only screen and (max-width: 480px) {
    padding: 4rem 1.5rem;
    }
`;

export const SecondaryPageContainer = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    margin: 0px;
    padding-inline: 0px;
    padding-top: 200px;
    /* background: linear-gradient( #ffffff, #f1f1f1); */
    background: transparent;
    & h1 {
  font-family: "Open Sans", sans-serif;
}

& h2 {
  font-family: "Open Sans", sans-serif;
}

& h3 {
  font-family: "Open Sans", sans-serif;
}

& h4 {
  font-family: "Open Sans", sans-serif;
}

& h5 {
  font-family: "Open Sans", sans-serif;
}

& h6 {
  font-family: "Open Sans", sans-serif;
}

& p {
  font-family: "Open Sans", sans-serif;
}

& span {
  font-family: "Open Sans", sans-serif;
}

& a {
  font-family: "Open Sans", sans-serif;
}
`;