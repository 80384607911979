import styled from "styled-components";
import { Link } from "react-router-dom";

import { BsFilePerson, BsBuilding, BsWhatsapp, BsHouse } from "react-icons/bs";
// import AiOutlineHome from 'react-icons/ai'BsPhoneVibrate
// AiOutlineHome  BsFilePerson IoBusinessOutline BsBuilding

const Wrapper = styled.div`
    max-width: 100%;
    height: 100%;
    margin: 2em auto;
  @media only screen and (min-width: 1080px) {
    display:none;
  }
`;

const Phone = styled.div`
    border-radius: 2em;
    position: fixed;
    bottom: -20px;
    height: auto;
    width: 100%;
    box-shadow: 0 40px 80px rgba(0,0,0,0.15);
    z-index: 3000;
`;

const Nav = styled.nav`
    position: absolute;
    width: 100%;
    bottom: 1em;
    padding:  9px 0;
    padding-top: 5px;
    left: 0;
    right: 0;
    background-color: #333;
    `;


const Ulist = styled.ul`
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 6px;
    margin: 0;
`;

const List = styled.li`
    display: block;

`;

const MobLink = styled(Link)`
    font-family: sans-serif;
    font-size: 11px;
    letter-spacing: 0px;
    text-decoration: none;
    color: #fff;
    line-height: 1em;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 79px;
    /* border-radius: 3em; */
    padding: 0.75em 1.25em;
    transition: 0.6s ease-in-out;
    &:hover, &:active, &isActive {
      color: #fff;
      background-color: #e26c1d;
    };

`;

const HomeIcon = styled(BsHouse)`
    width: 24px;
    height: 24px;
    font-size: 24px;
    padding-bottom: 6px;
`;

const PersonIcon = styled(BsFilePerson)`
    width: 24px;
    height: 24px;
    font-size: 24px;
    padding-bottom: 6px;
`;

const BuildingIcon = styled(BsBuilding)`
    width: 24px;
    height: 24px;
    font-size: 24px;
    padding-bottom: 6px;
`;

const WhatsappIcon = styled(BsWhatsapp)`
    width: 24px;
    height: 24px;
    font-size: 24px;
    padding-bottom: 6px;
`;

// const TextSpan = styled.span`
//     display: inline-block;
//     overflow: hidden;
//     max-width: 0;
//     opacity: 0;
//     color: #fff;
//     padding-left: 0.5em;
//     margin-left: 0.5em;
//     transform: translate3d(-0.5em, 0, 0);
//     transition: opacity 0.6s, max-width 0.6s, transform 0.6s;
//     transition-timing-function: ease-in-out;
//     &:hover, &:active, &isActive {
//       opacity: 1;
//       max-width: 60px;
//       height: 13px;
//       color: #fff;
//       transform: translate3d(0, 0, 0);
//     }
// `;


const MobNavbar = () => {
  return (
      <Wrapper>
        <Phone>
          <Nav>
            <Ulist>
              <List>
                <MobLink to="/">
                <HomeIcon />Home
                  {/* <TextSpan>Home</TextSpan> */}
                </MobLink>
              </List>
              <List>
                <MobLink to="/Persona">
                  <PersonIcon />Persona
                  {/* <TextSpan></TextSpan> */}
                </MobLink>
              </List>
              <List>
                <MobLink to="/Empresa">
                <BuildingIcon />Empresa
                  {/* <TextSpan>Empresa</TextSpan>     */}
                </MobLink>
              </List>
              <List>
                <MobLink to="https://wa.me/5491122534639">
                <WhatsappIcon />WhatsApp
                  {/* <TextSpan></TextSpan>     */}
                </MobLink>
              </List>
            </Ulist>
          </Nav>
        </Phone>
      </Wrapper>
  )
}

export default MobNavbar