import styled from "styled-components";
import {useState} from 'react';
import { FaChevronDown } from "react-icons/fa"


const Item = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 890px) {
      width: 100%;
    }
    `;

const ServiceItemG = styled.button`
  width: 80%;
  font-size: 22px;
  text-align: left;
  color: #fff;
  background: linear-gradient(to right , #595858, #444444);
  margin: 10px 0;
  margin-inline: auto;
  border: none;
  padding: 12px 20px;
  border-radius: 12px;
  @media only screen and (max-width: 940px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const Arrow = styled.span`
  font-size: 14px;
  text-align: left;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const ServiceItemInfo = styled.p`
  width: 85%;
  font-size: 14px;
  text-align: left;
  color: #333;
  margin: 10px 0;
  padding: 0 40px;
  margin-inline: auto;
  @media only screen and (max-width: 890px) {
    width: 100%;
    font-size: 15px;
    padding: 0 15px;
  }
`;

const Bold = styled.p`
  width: 85%;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  color: #333;
  margin: 10px 0;
  padding: 0 40px;
  margin-inline: auto;
  @media only screen and (max-width: 890px) {
    width: 100%;
    font-size: 15px;
    padding: 0 15px;
  }
`;

export default function Item3() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {

    setIsShown(current => !current);

  };

  return (
    <Item>
      <ServiceItemG onClick={handleClick}>Evaluación del Personal<Arrow><FaChevronDown /></Arrow></ServiceItemG>

      {isShown ? (
        <div>
          <Bold>Assessment Center: </Bold>
          <ServiceItemInfo>
Es una técnica de evaluación grupal, que nos permite conocer habilidades, actitudes y potencialidad del candidato. La utilizamos n la selección de personal
o desarrollo, ya sea para futuros postulantes o en el personal que trabaja en la organización.
</ServiceItemInfo>
          <Bold>Evaluación psicotécnica: </Bold>
          <ServiceItemInfo>
          A través d e un conjunto de tests proyectivos y psicométricos se indagarán en profundidad las aptitudes y competencias requeridas para desempeñarse de manera efectiva en el puesto a cubrir.
</ServiceItemInfo>
          <Bold>Evaluación grafológica: </Bold>
          <ServiceItemInfo>
          Esta técnica proyectiva es fácil de suministrar. No genera tensión en el postulante va que el escribir es un acto automático que refleja libremente el inconsciente. Permite obtener información idónea sobre la característica de la personalidad, confiabilidad, habilidades y competencias especificas, indispensables para la incorporacion del candidato.
</ServiceItemInfo>
          <Bold>Seguimiento de las incorporaciones: </Bold>
          <ServiceItemInfo>
Es Una vez que el candidato fue incorporado a tu empresa, nosotros realizaremos un seguimiento y acompañamiento para conocer cómo se adaptó y si necesita atención para el período de adaptación. Este acompañamiento es sin cargo.
</ServiceItemInfo>
         
        </div>
      ) : null}

    </Item>
  );
}
