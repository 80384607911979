import styled from "styled-components";
import Office from "../assets/glow/office.webp"

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  @media only screen and (max-width: 1080px) {
      flex-direction: column;
    }
    `;

const Left = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; 
  `;

const WebpOffice = styled.img`
    width: 100%;
    `;

const Right = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #d8d5d7;
  padding: 30px 0;
  @media only screen and (max-width: 1080px) {
    padding: 60px 0;
    }
  @media only screen and (max-width: 480px) {
    padding: 50px 0;
    }
    `;

const Item = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 980px) {
    flex-direction: column;
    }
`;

const RightH2 = styled.h2`
    width: 90%;
    font-size: 50px;
    text-transform: uppercase;
    /* margin-top: 30px; */
    margin-bottom: 30px;
    text-align: left;
    color: #f57926;
    font-weight: 300;
    /* font-family: 'Source Sans Pro', sans-serif; */
    @media only screen and (max-width: 480px) {
    font-size: 36px;
    margin-bottom: 22px;

    }
`;

const SpanRight = styled.span`
    width: 90%;
    font-size: 18px;
    text-align: left;
    color: #444;
    font-weight: 700;
    /* margin-left: -130px; */
    @media only screen and (max-width: 480px) {
    font-size: 14px;
    }
`;


const RightDesc = styled.p`
  width: 90%;
  font-size: 16px;
  @media only screen and (max-width: 780px) {
    font-size: 14px;
  }
`;


const AboutGlow = () => {
  return (
    <>
    <Container>

      <Left>
        <WebpOffice src={Office} />
      </Left>
      <Right> 
        <Item>
    <RightH2>NOSOTROS</RightH2>
        <SpanRight>Somos una consultora destinada a la búsqueda y selección de talentos y servicios afines a RR-HH.</SpanRight><br />
        <RightDesc>
            Buscamos acompañar a pymes, empresas y personas para que logren trascender en el mercado, innovando y proyectando un futuro sin límites.<br /><br />
            Cada uno de nuestros servicios está diseñado de manera estratégica para la realidad de cada empresa, individuo y pyme.<br /><br />
            Trabajamos a conciencia, buscando realizar acompañamientos 100% efectivos para cada necesidad.<br /><br />
            Contamos con un equipo de profesionales inter isciplinarios que reúnen las características necesarias para que el servicio que brindamos sea de excelencia.        </RightDesc>
        </Item>
      </Right>
    </Container>    
    
    
    
    </>
  )
}

export default AboutGlow