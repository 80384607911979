import styled from "styled-components";
import {useState} from 'react';
import { FaChevronDown } from "react-icons/fa"


const Item = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 890px) {
      width: 100%;
    }
    `;

const ServiceItemO = styled.button`
  width: 80%;
  font-size: 22px;
  text-align: left;
  color: #fff;
  background: linear-gradient(to right , #f59121, #f16b3e, #ee5a27);
  margin: 10px 0;
  margin-inline: auto;
  border: none;
  padding: 12px 20px;
  border-radius: 12px;
  @media only screen and (max-width: 940px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const Arrow = styled.span`
  font-size: 14px;
  text-align: left;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

const ServiceItemInfo = styled.p`
  width: 85%;
  font-size: 14px;
  text-align: left;
  color: #333;
  margin: 10px 0;
  padding: 0 40px;
  margin-inline: auto;
  @media only screen and (max-width: 890px) {
    width: 100%;
    font-size: 15px;
    padding: 0 15px;
  }
`;


export default function Item8() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {

    setIsShown(current => !current);

  };

  return (
    <Item>
      <ServiceItemO onClick={handleClick}>Cursos On-demand<Arrow><FaChevronDown /></Arrow></ServiceItemO>

      {isShown ? (
        <div>
          <ServiceItemInfo>
          Ofrecemos cursos On-demand para hacerlos a tu ritmo: Inteligencia emocional, comunicación efectiva,liderazgo y 
          gestión de equipos, bienestar personal y organizacional. Te brindamos tiempo de aprendizaje para que puedas potencializar tu 
          vida laboral, crecer y lograr alcanzar tus metas. Contarás con mentorías personalizadas con profesionales para generar mayores resultados. Cada curso 
          cuenta con certificado. A través de este servicio, la persona afectada adquiere una nueva posibilidad de cambio laboral y confianza profesional. Se 
          establecen nuevas metas en gestión personal, a través de un programa diseñado a medida. Acompañamos durante el proceso de desarrollo, para eficientizar 
          la reinserción del profesional.
          </ServiceItemInfo>
        </div>
      ) : null}

    </Item>
  );
}
