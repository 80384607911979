// import styled from "styled-components"
import styled from "styled-components";
import WaterMark from "../assets/glow/loguito3.png";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  position: relative;
  margin-top: -2px;
  background: url(${WaterMark});
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f3f3f3;

  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    background-size: 80%;
  }
`;

const Left = styled.div`
  width: 100%;
  padding: 140px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1080px) {
    padding-top: 70px;
    padding-bottom: 30px;
  }
`;

const LeftH4 = styled.h4`
  width: 100%;
  font-size: 22px;
  text-transform: uppercase;
  margin: 15px 0;
  text-align: center;
  color: #cc5e15;
  font-weight: 500;
  @media only screen and (max-width: 1080px) {
    font-size: 21px;
  }
`;

const LeftDesc = styled.p`
  width: 80%;
  font-size: 16px;
  text-align: center;
  @media only screen and (max-width: 1080px) {
    width: 90%;
  }
`;

const LeftButton = styled.button`
  border: 2px solid #e26c1d;
  padding: 15px 35px;
  background-color: transparent;
  text-transform: uppercase;
  color: #464444;
  font-weight: bold;
  font-size: large;
  border-radius: 15px;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    margin-bottom: 20px;
  }
`;

const Right = styled.div`
  width: 100%;
  padding: 140px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1080px) {
    padding-top: 40px;
    padding-bottom: 90px;
  }
`;

const RightH4 = styled.h4`
  width: 100%;
  font-size: 22px;
  text-transform: uppercase;
  margin: 10px 0;
  text-align: center;
  color: #cc5e15;
  font-weight: 500;
  /* margin-left: -130px; */
  @media only screen and (max-width: 1080px) {
    text-align: center;
    font-size: 20px;
  }
`;

const RightDesc = styled.p`
  width: 80%;
  font-size: 16px;
  text-align: center;

  @media only screen and (max-width: 1080px) {
    width: 90%;
  }
`;

const RightButton = styled.button`
  border: 2px solid #cc5e15;
  padding: 15px 35px;
  background-color: transparent;
  text-transform: uppercase;
  color: #464444;
  font-weight: bold;
  font-size: large;
  border-radius: 15px;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    margin-bottom: 20px;
  }
`;

const Register = () => {
  return (
    <Container>
      <Left>
        <LeftDesc>
          <LeftH4>SERVICIO DE UNO A UNO</LeftH4>
          Completá el formulario, cargá tu CV y recibí servicio uno a uno, te
          contactamos con empresas reales en búsqueda de personal en relación a
          tu perfil.
          <br />
          <br />
          <br />
        </LeftDesc>
        <Link to="Persona">
          <LeftButton>Soy Persona</LeftButton>
        </Link>
      </Left>
      <Right>
        <RightDesc>
          <RightH4>GARANTÍA POR SERVICIO DE SELECCIÓN</RightH4>
          Garantía 30 días sobre el personal incorporado por faltas continuas o
          falta de competencia a la hora de desempeñar su rol, o falta de
          recursos académicos.
          <br />
          <br />
          <br />
        </RightDesc>
        <Link to="Empresa">
          <RightButton>Soy Empresa</RightButton>
        </Link>
      </Right>
    </Container>
  );
};

export default Register;
