import React from 'react'
import styled from 'styled-components'
import "./businessForm.css"

const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
  display: flex;
  flex-direction: column;
  background: rgb(240, 240, 240);
  margin-top: 0rem;
  padding: 3rem;
  gap: 2rem;
  text-align: left;
  overflow: hidden; /* Hide scrollbars */

  font-family: "Open Sans", sans-serif;
  @media only screen and (max-width: 880px) {
    padding: 4rem 1rem;
  }
  @media only screen and (max-width: 480px) {
    padding: 4rem 0rem;
    margin-top: 0px;
  }
`;


const BusinessForm = () => {
  return (
    <Container>
      <iframe
        className="iframe-form"
        src="https://forms.glowconsultora.com.ar/empresa.html"
      ></iframe>
  </Container>
  )
}

export default BusinessForm