import "../components/settings/style.css";

export default function GlowOoops() {

  return (
    <div id="error-page">
      <h1>404</h1>
      <p>La página que buscás no existe</p>
      <p>
        Es muy posible que haya un error en el enlace que ingresaste
      </p>
    </div>
  );
}