import Navbar from "./components/navbar/Navbar";
import "./components/settings/style.css";
import MobNavbar from "./components/MobNavbar";
// import AppRoutes from "./components/routes/AppRoutes";
import { Routes, Route } from "react-router-dom"
import Home from "./pages/Home";
import SoyEmpresa from "./pages/SoyEmpresa";
import SoyPersona from "./pages/SoyPersona";
import Contact from "./pages/Contact";
import About from "./pages/About";
import GlowOoops from "./pages/GlowOoops";



const App = () => {
  return (
    <>
      <Navbar />
      <MobNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Persona" element={<SoyPersona />} />
        <Route path="/Empresa" element={<SoyEmpresa />} />
        <Route path="/Contactanos" element={<Contact />} />
        <Route path="/Glow" element={<About />} />
        <Route path="*" element={<GlowOoops />} />
      </Routes>
    </>
  );
};

export default App;